<template>
  <nav>
    <ul class="pagination">
        
      <li class="page-item disabled" @click="prev">
        <span class="page-link">‹</span>
      </li>

      <li class="page-item " :class="page == i ? 'active' : ''" v-for="i in beforePage" :key="i" @click="pageChange(i)"><span class="page-link">{{i}}</span></li>           

      <li class="page-item disabled" v-if="showCenter"><span class="page-link">...</span></li>

      <li class="page-item " :class="beforeSize == i ? 'active' : ''" v-for="i in centerPage" :key="`center${i}`" @click="pageChange(page - beforeSize + i)"><span class="page-link">{{page - beforeSize + i}}</span></li>                 

      <li class="page-item disabled" v-if="showMore"><span class="page-link">...</span></li>

      <li class="page-item " @click="pageChange(totalPage - 1)"  v-if="showMore"><span class="page-link">{{totalPage - 1}}</span></li>                 
      <li class="page-item " @click="pageChange(totalPage)" v-if="showMore"><span class="page-link">{{totalPage}}</span></li>     

      <li class="page-item" @click="next">
        <a class="page-link" >›</a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      beforePage: 8,
      centerPage: 0,
      beforeSize: 4,
      showMore: false,
      showCenter: false,
    }
  },
  props: {
    totalPage: Number,
    page: {
      type: Number,
      default: 1
    }
  },
  watch: {
    totalPage() {
      this.initPage()
    },
    page() {
      this.initPage()
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    initPage() {
      if (this.totalPage > 10) {
        if (this.totalPage - this.page > 5) {
          this.showMore = true
        } else {
          this.showMore = false
        }
        
        if (this.page > 7) {
          if (this.totalPage - this.page > 5) { 
            this.centerPage = 7
            this.beforeSize = 4
          } else {
            this.centerPage = 8 
            this.beforeSize = 8 + this.page - this.totalPage
          }
          this.beforePage = 2
          this.showCenter = true
        } else {
          this.showCenter = false
          this.beforePage = 8
          this.centerPage = 0
        }
      } else {
        this.beforePage = this.totalPage
        this.showMore = false
      }
    },
    pageChange(val) {
      this.$emit('change', val)
    },
    next() {
      if (this.page == this.totalPage) {
        return
      }
      this.$emit('change', this.page + 1)
    },
    prev() {
      if (this.page == 1) {
        return
      }
      this.$emit('change', this.page - 1)
    }
  }
}
</script>