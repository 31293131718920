<template>
  <div  class="px-15px px-lg-25px">
   <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('tui-kuan-qing-qiu') }}</h1>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header row gutters-5">
        <h5 class="mb-0 h6">{{ $t('suo-you-tui-kuan-qing-qiu') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0">
          <thead>
            <tr>
                <th>#</th>
              <th data-breakpoints="lg">{{ $t('ri-qi') }}</th>
              <th>{{ $t('ding-dan-hao-0') }}</th>
              <th data-breakpoints="lg">{{ $t('chan-pin') }}</th>
              <th data-breakpoints="lg">{{ $t('jin-e') }}</th>
              <th data-breakpoints="lg">{{ $t('zhuang-tai') }}</th>
              <th data-breakpoints="lg">{{ $t('yuan-yin') }}</th>
              <th>{{ $t('tong-guo') }}</th>
              <th data-breakpoints="lg">{{ $t('ju-jue') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>{{i + 1 }}</td>
              <td>{{item.CreateAt}}</td>
              <td>{{item.code}}</td>
              <td>{{item.name}}</td>
              <td>$123.31</td>
              <td>
                <span class="badge badge-inline badge-success"><strong>{{ $t('yi-tong-guo-0') }}</strong></span>
                <span class="badge badge-inline badge-danger"><strong>{{ $t('yi-ju-jue-0') }}</strong></span>
                <span class="badge badge-inline badge-warning"><strong>{{ $t('shen-he-zhong') }}</strong></span>
              </td>
              <td>
                  <a ><span class="badge badge-inline badge-success">{{ $t('cha-kan') }}</span></a>
              </td>
              <td>
                <label class="aiz-switch aiz-switch-success mb-0">
                  <input  type="checkbox" >
                  <span class="slider round"></span>
                </label>
              </td>
              <td>
                <a class="btn btn-soft-danger btn-icon btn-circle btn-sm" :title="$t('ju-jue-tui-kuan-qing-qiu')" @click="openModal">
                  <i class="las la-trash"></i>
                </a>
                <a href="javascript:void(0);" class="btn btn-soft-primary btn-icon btn-circle btn-sm" :title="$t('ju-jue-yuan-yin')" @click="openReasonModal">
                  <i class="las la-eye"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="aiz-pagination">
          <pageBar :total="13" :page="1"></pageBar>
        </div>
      </div>
    </div>

    <Popup v-model="showPopup">
      <div class="popup-box " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('ju-jue-tui-kuan-qing-qiu-0') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label">{{ $t('ju-jue-yuan-yin-0') }}</label>
                  <div class="col-md-9">
                    <textarea type="text" name="reject_reason" rows="5" class="form-control height-auto" :placeholder="$t('ju-jue-yuan-yin-1')" required></textarea>
                  </div>
                </div>
                <div class="form-group text-right">
                  <button type="submit" class="btn btn-sm btn-primary transition-3d-hover mr-1">{{ $t('que-ding') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>

    <Popup v-model="showReasonPopup">
      <div class="popup-box " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('ju-jue-yuan-yin-2') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <div class="modal-body gry-bg px-3 pt-3">
              <div class="pl-2 pr-2">
                {{ $t('yuan-yin-0') }} </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-dismiss="modal" @click="cancel">{{ $t('guan-bi') }}</button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      showReasonPopup: false,
      optionList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: '1',
          label: this.$t('jin-tian')
        },
        {
          value: '2',
          label: this.$t('zui-jin-7-tian')
        },
        {
          value: '3',
          label: this.$t('zui-jin-30-tian')
        },
      ],
      tableData: []
    }
  },
  methods: {
    resetPrice,
    cancel() {
      this.showPopup = false
      this.showReasonPopup = false
    },
    openModal() {
      this.showPopup = true
    },
    openReasonModal() {
      this.showReasonPopup = true
    },
  }
}
</script>